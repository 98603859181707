<template>
    <div>
        <b-card no-body class="p-1 mb-2">
            <div class="row">
                <b-col cols="3">
                    <router-link :to="{name: 'SanitaryInspectionActCreate'}" class="btn btn-success">
                        <b-icon-plus scale="1.2"/>
                    </router-link>
                </b-col>
                <b-col cols="9" class="text-right">
                    <b-button variant="primary" @click="applyFilters" class="mr-1">
                        <b-icon-arrow-repeat font-scale="1.2"/>
                    </b-button>
                    <b-button v-b-toggle.filters variant="light">
                        <b-icon-filter/>
                        Фильтры
                    </b-button>
                    <small v-if="filtersExists">
                        <b-link @click.prevent="resetFilters">(Сбросить)</b-link>
                    </small>
                </b-col>
            </div>
            <b-collapse id="filters">
                <div class="card-body">
                    <b-row class="mt-2">
                        <b-col md="4">
                            <b-form-group description="Дата от">
                                <input type="date" class="form-control" v-model="filters.dateFrom"/>
                            </b-form-group>
                        </b-col>
                        <b-col md="4">
                            <b-form-group description="Дата до">
                                <input type="date" class="form-control" v-model="filters.dateTo"/>
                            </b-form-group>
                        </b-col>
                        <b-col md="4">
                            <b-form-group description="Подразделение">
                                <department-picker v-model="filters.department" :allowedOnly="true"/>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
            </b-collapse>
        </b-card>

        <b-table
            stipred
            responsive
            class="act-table"
            head-variant="dark"
            :items="items"
            :fields="fields"
            no-local-sorting
            @row-dblclicked="goToForm"
        >
            <template #cell(actions)="data">
                <b-button size="sm" variant="warning" :to="goToForm(data.item.id)">
                    <b-icon-pencil-square/>
                </b-button>
            </template>
        </b-table>

        <b-row>
            <b-col md="6">
            <span>
                Показано с {{ meta.current_page * meta.per_page - meta.per_page + 1 }}
                по {{ meta.current_page * meta.per_page - meta.per_page + items.length }}
                из {{ meta.total_rows }} записей
            </span>
            </b-col>
            <b-col md="6">
                <b-pagination
                    v-model="meta.current_page"
                    :total-rows="meta.total_rows"
                    :per-page="meta.per_page"
                    @input="applyFilters"
                    class="justify-content-end"
                />
            </b-col>
        </b-row>
    </div>
</template>

<script>
import moment                             from "moment"
import DepartmentPicker                   from "@components/_common/DepartmentPicker"
import lsfMixin                           from "@utils/localStorageFilterMixin"
import {SANITARY_INSPECTION_ACT_ENDPOINT} from "@utils/endpoints"

const RECORD_PER_PAGE = 50

const FILTERS_PATTERN = {
    dateTo: null,
    dateFrom: null,
    department: null,
    reason: null,
    transfer: null,
}

export default {
    name: "Index",

    mixins: [lsfMixin],
    components: {DepartmentPicker},

    data() {
        return {
            items: [],
            fields: [
                {key: "id", label: "№"},
                {
                    key: "actualShift",
                    label: "Дата и время проверки",
                    formatter(value, key, item) {
                        return `${moment(value.date).format('DD.MM.YYYY')} ${moment(item.time, 'HH:mm:ss').format('HH:mm')}`;
                    },
                },
                {key: "department.name", label: "Подразделение"},
                {key: "actualShift.shift.name", label: "Смена"},
                {key: "score", label: "Результат 1 проверки (балл)"},
                {key: "additional_score", label: "Результат 2 проверки (балл)"},
                {key: "createdBy.fullname", label: "Провел проверку"},
                {
                    key: "created_at",
                    label: "Дата и время создания",
                    formatter(created_at) {
                        return moment(created_at).format('DD.MM.YYYY HH:mm')
                    },
                },
                {key: "comment", label: "Комментарий"},
                {key: "actions", label: "Действия"},
            ],
            meta: {
                total_rows: 0,
                current_page: 1,
                per_page: 25,
            }
        }
    },

    watch: {
        filters: {
            deep: true,
            handler(value) {
                this.watchFilters(value)
            },
        },
    },

    computed: {
        noLocalSort() {
            return true
        },
        getTotalPages() {
            return Math.ceil(this.totalRows / RECORD_PER_PAGE)
        },

        preparedConditions() {
            let conditions = {
                with: ["actualShift.shift", "department", "createdBy", "updatedBy"],
                filters: this.filters,
                limit: 50,
            }

            conditions.page = this.meta.current_page;

            return conditions
        },
    },

    methods: {
        generationLink(number) {
            return number;
        },
        goToForm(id) {
            return {name: "SanitaryInspectionActUpdate", params: {id}}
        },

        applyFilters() {
            let params = this.preparedConditions

            this.$http.get(SANITARY_INSPECTION_ACT_ENDPOINT, {params}).then((response) => {
                this.meta = {
                    total_rows: response.data.data.total,
                    current_page: response.data.data.current_page,
                    per_page: response.data.data.per_page,
                }
                this.items = response.data.data.data
            })
        },
    },

    created() {
        this.initFilters(this, FILTERS_PATTERN)
    },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
